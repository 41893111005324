import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import Header from "../component/header/HeaderDetails";

 class error404 extends Component {
    
    render() {
        return (
            <>
                <Header headerPosition="header--transparent" color="color-white" logo="logo-light" />
                {/* Start Page Error  */}
                <div className="error-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title">Oops!</h1>
                                    <h3 className="sub-title mt--40">Don't be sad, it's just a 404 Error!</h3>
                                    <div className="error-button mt--40">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div className="panels-right bottom">
                    <img className="image panel-xy-1x1" id="Panel16" data-scroll-speed="15" sizes="100vw" srcSet="/assets/images/svgs/shape1.svg"
                         alt=""></img>
                    <img className="image panel-xy-2x1" id="Panel17" data-scroll-speed="15" sizes="100vw"
                         srcSet="/assets/images/svgs/shape2.svg"
                         alt=""></img>
                    <img className="image panel-xy-3x1" data-scroll-speed="15" id="Panel18" sizes="100vw"
                         srcSet="/assets/images/svgs/shape3.svg"
                         alt=""></img>
                  </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 
            </>
        )
    }
}
export default error404;
