import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                      <a href="http://vanillaforums.com/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/vanilla.png" alt="Vanilla Logo"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://community.zapier.com/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/zapier.png" alt="Zapier Logo"/></a>
                    </li>
                    <li>
                      <a href="https://zentalk.asus.com/en/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/asus.png" alt="Asus Logo"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://community.harrypotterwizardsunite.com/en/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/hp.png" alt="Harry Potter Logo"/></a>
                    </li>
                    <li>
                      <a href="https://apkpure.com/lyftnote/com.lyftnote.lyftnote" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/lyftnote.png" alt="Lyftnote Logo"/></a>
                    </li>
                    <li>
                      <a href="https://motovan.com" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/motovan.png" alt="Motovan Inc Logo"/></a>
                    </li>
                    <li>
                        <img src="/assets/images/brand/roadkrome.png" alt="Roadkrome Logo"/>
                    </li>
                    <li>
                      <a href="https://olympiamotosports.com/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/olympia.png" alt="Olympia Motosports Logo"/></a>
                    </li>
                    <li>
                      <a href="https://zoxhelmets.com/" rel="noopener noreferrer" target="_blank">
                        <img src="/assets/images/brand/zox.png" alt="Zox Helmets Logo"/></a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
